import React, { useCallback, useState } from "react"
import { useHistory } from "react-router-dom"
import { useAsync } from "react-use"
import Select  from "react-select"
import { saveAs } from 'file-saver'
import photoStandards from "data/photo-standards.json"
import axios from "axios"
import { Button } from "../components/Button"
import { Header } from "../components/Header"
import { Process } from "../components/Process"
import { RatingPopup } from "../components/RatingPopup"
import { Alert } from "../components/Alert"
import { Loading } from "../components/Loading"
import { useTranslation } from "react-i18next"
import { LoadingAnim, PhotoIcon } from "../assets"
import { PhotoStandard } from "../models/datatypes"
import store from "../store"
import { ImageObj } from "../common/types"

export const PreviewPhotoLitePage = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const storeDomain = "https://storage.googleapis.com/"

  const [value, setValue] = useState<PhotoStandard | null>(null)
  const [url, setUrl] = useState<string>("")
  const [orginalImage, setOriginalImage] = useState("")
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(true)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [currentBlob, setCurrentBlob] = useState(null)
  const [ratingModal, setRatingModal] = useState<boolean>(false)
  const [ratingCount, setRatingCount] = useState<number>(0)

  useAsync(async () => {
    setLoading(true)
    const file = store.files[0]

    if (!file) {
      throw new Error("Can not find file")
    }

    const result = new Blob([file],{type: 'image/jpg'})
    const formData = new FormData()
    formData.append('file', result)

    const data: ImageObj[] = await axios({
        url: `${process.env.REACT_APP_UPLOADER_URL}/v2/upload`,
        method: "POST",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          "Application-Type": "passport",
          "Application-Stage": process.env.REACT_APP_STAGE,
        },
    }).then(resp => resp.data)    
    
    const id = data[0].id
    const path = id.substr(0, id.lastIndexOf("/"));
    const url = storeDomain + path

    setUrl(url)
    setOriginalImage(url)
    setLoading(false)
  }, [])

  const onChangePhotoType = (value) => {
    setRatingCount(0)
    setUrl(orginalImage)
    setValue(value)
  }

  const onSubmit = useCallback(async () => {
    try {

      if (!value) {
        return
      }

      if (ratingCount === 1) {
        saveAs(new Blob([currentBlob]),"image.jpg");      
        return
      }

      if (ratingCount === 0) {

        const { dimensions } = value

        setLoading(true)
        setSubmitLoading(true)
        
        const body = {
          "img_url": url,
          "pp_type": {
            "unit": dimensions.units,
            height: dimensions.pictureHeight,
            width: dimensions.pictureWidth,
            crown_top: dimensions.crownTop,
            "face_rate": 0.6
          },
          "tax": 0
        }

        const data = await axios({
          url: `${process.env.REACT_APP_PASSPORT_URL}/create_passport_by_file`,
          method: "POST",
          data: body,
          responseType: 'blob'
        }).then(resp => resp.data)

        
        const processedImage  = URL.createObjectURL(data)
        
        setCurrentBlob(data)
        setUrl(processedImage)
        saveAs(new Blob([data]),"image.jpg");
        setSubmitLoading(false)
        setLoading(false)
        setRatingModal(true)
        setRatingCount(1)
      }

    } catch (e) {
      setError(e.message)
      setSubmitLoading(false)
      setLoading(false)
    }
  }, [
    url,
    value,
    currentBlob,
    ratingCount
  ])

  const onSendFeedback = useCallback(
    async ({ feedback, rating }) => {

      console.log(feedback)
      console.log(rating)
    },
    []
  )

  return (
    <div className={"container preview-lite"}>
      <Header />
      <Process step={1} />

      <RatingPopup
        visible={ratingModal}
        onClose={() => setRatingModal(false)}
        onSubmit={async data => {
          setRatingModal(false)
          await onSendFeedback(data)
        }}
       />

      <div className={"wrapper"}>
        <span className={"wrapper__title"}>{t("text.select_photo_type")}</span>
        <Select<PhotoStandard>
          isMulti={false}
          isDisabled={loading}
          isSearchable={true}
          isClearable={true}
          closeMenuOnSelect={true}
          value={value}
          getOptionLabel={v => v.text}
          getOptionValue={v => v.text}
          onChange={onChangePhotoType}
          options={photoStandards}
        />
      </div>

      <div className={"image"}>
        {/*<div ref={loadingRef} className={"image__loading"}/>*/}
        {!!url ? (
          <img src={url} alt="photo_display" />
        ) : loading ? (
          <LoadingAnim />          
        ) : (
          <PhotoIcon width={120} height={120} />
        )}
      </div>

      <Loading visible={submitLoading}>{t("text.submit_processing_image")}</Loading>

      <Button
        disabled={loading || !url || !value}
        absolute
        title={
          submitLoading || loading
            ? t("button.processing")
            : t("button.download")
        }
        onClick={onSubmit}
        loading={submitLoading}
      />

      <Alert
        visible={!store.hasFiles}
        buttonTitle={t("button.go_back")}
        message={t("text.missing_image")}
        title={t("text.sth_went_wrong")}
        onClick={() => {
          history.replace("camera")
        }}
      />

      <Alert
        visible={!!error}
        buttonTitle={t("button.go_back")}
        message={error}
        title={t("text.sth_went_wrong")}
        onClick={() => {
          history.replace("camera")
        }}
      />

      {/*language=Stylus*/}
      <style jsx>{`
        .container {
          //display flex
          //flex-direction column
          height 100vh
        }
        
        .image {
          display flex
          justify-content center
          align-items center
          //margin-top 100px
          //margin-bottom 82px
          flex 1
          position relative
          padding 40px
          padding-bottom 120px
        
          img {
            width 80%
            padding 8px
            background #EAF0FF
          }
          
          &__loading {
            width 100px
            height 100px
          }
        }
        
        .wrapper {
          padding 16px
          padding-bottom 0
          
          &__title {
            font-size 14px
            line-height 20px
            font-weight 500
            margin-bottom 2px
          }
          
          &__number-input {
            margin-top 12px
            flex 1
            display flex
            justify-content center
          }
        }
      `}</style>
    </div>
  )
}

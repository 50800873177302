import React, { useCallback, useRef, useState } from "react"
import { Button } from "../components/Button"
import { useWindowSize } from "react-use"
import { useHistory } from "react-router-dom"
import { toBase64, urlToFile } from "../functions/file-extra"
import { colors } from "../variables"
import { CaptureButton } from "../components/CaptureButton"
import { SwitchCamButton } from "../components/SwitchCamButton"
import { Process } from "../components/Process"
import { Alert } from "../components/Alert"
import { PhotoIcon } from "../assets"
import { useTranslation } from "react-i18next"
import Webcam from "react-webcam"
import store from "../store"

export type DividerProps = {
  width: number
}

export const Divider: React.FC<DividerProps> = ({ width }) => (
  <div style={{ width }} />
)

const HEADER_HEIGHT = 98

export const CameraPage = () => {
  const history = useHistory()
  const { t } = useTranslation()

  const [facingMode, setFacingMode] = useState("user")
  const [error, setError] = useState("")
  const [data, setData] = useState<string | null>(null)
  const { width } = useWindowSize()

  const onUploadFile = useCallback(async e => {
    const files = e.target.files
    if (!files) {
      return
    }

    const file = files[0]
    if (!file) {
      return
    }

    const data = await toBase64(file)

    store.files = files

    setData(data)
  }, [])

  const onSubmit = useCallback(async () => {
    await history.push({
      pathname: "preview",
    })
  }, [history])

  const webcamRef = useRef<Webcam>(null)

  const onCapture = useCallback(async (e) => {
    if (!webcamRef.current) {
      console.log('missing webcam ref')
      return
    }

    const imageSrc = webcamRef.current.getScreenshot()
    const file = await urlToFile(imageSrc!, 'camera-image', 'image/*')
    store.files = [file]

    setData(imageSrc)
  }, [webcamRef])

  const onClear = useCallback(() => {
    if (!webcamRef.current) {
      return
    }

    setData(null)
  }, [webcamRef])

  const onSwitchCam = useCallback(() => {
    setFacingMode(facingMode === "user" ? "environment" : "user")
  }, [facingMode])

  return (
    <div className={"container"}>
      <div className={"header"}>
        <Process />
        <div className={"header__message"}>
          <span>{t("text.take_photo")}</span>
          <br />
          <span>{t("text.pls_map_face_to_placeholder")}</span>
        </div>
      </div>

      <div className={"camera"}>
        {/*<PhotoPlaceholder />*/}
        {/*{!url && <video autoPlay={true} ref={videoRef} />}*/}
        {/*<video autoPlay={true} ref={ØvideoRef} />*/}
        <Webcam
          mirrored={facingMode === 'user'}
          audio={false}
          // height={'100%'}
          ref={webcamRef as any}
          screenshotFormat="image/jpeg"
          width={width}
          videoConstraints={{
            // width: 720,
            // height: 1280,
            facingMode
          }}
        />
        {!!data && (
          <div className={"camera__image"}>
            <img src={data} alt="" />
          </div>
        )}
      </div>

      {!data ? (
        <div className={"actions"}>
          <div className={"actions__fixed"}>
            <label htmlFor={"select-file"} className={"actions__file"}>
              <PhotoIcon />
            </label>
            <input
              accept="image/*"
              type="file"
              id={"select-file"}
              onChange={onUploadFile}
              style={{ display: "none" }}
            />
            <CaptureButton onClick={onCapture} />
            <SwitchCamButton onClick={onSwitchCam} />
          </div>
        </div>
      ) : (
        <div className={"actions"}>
          <div className={"actions__fixed"}>
            <Button
              background={"#fff"}
              color={"#142246"}
              title={t("button.retake")}
              onClick={onClear}
            />
            <Divider width={15} />
            <Button title={t("button.use")} onClick={onSubmit} />
          </div>
        </div>
      )}

      <Alert
        visible={!!error}
        buttonTitle={t("button.go_back")}
        title={t("text.sth_went_wrong")}
        message={error}
        onClick={() => {
          setError("")
          // history.goBack()
        }}
      />

      {/*language=Stylus*/}
      <style jsx>{`
        .container {
          padding 0
          height 100%
          overflow hidden
          -webkit-overflow-scrolling touch
        }
        
        .header {
          height ${HEADER_HEIGHT}px
          display flex
          flex-direction column
          justify-content center
          
          background #030821
          z-index 100
          
          &__message {
            padding-top 0
            line-height 20px
            text-align center
            font-size 12px
            color #FFF
            
            span:first-child {
              color ${colors.accent}
            }
          }
        }
        
        .camera {
          background black
          //display flex
          overflow hidden
          position relative
          height 100%
          
          video {
            //width 100%
            //height calc(100vh - ${HEADER_HEIGHT}px)
            //transform scaleX(-1)
          }
          
          &__preview {
             position absolute
             top ${HEADER_HEIGHT}px
             left 0
             right 0
             overflow hidden
             display flex
             justify-content center
             align-items center
             height calc(100vh - ${HEADER_HEIGHT}px)
            
            canvas {
              width ${width}px
            }
          }
          
          &__image {
            position absolute
            top 0
            left 0
            right 0
            background black
            justify-content center
            align-items center
            display flex
            width 100%
            
            img {
              height calc(100vh - ${HEADER_HEIGHT}px)
              width 100%
              object-fit contain
            }
          }
        }
        
        .actions {
          //transform translate3d(0,0,0)
          
          &__fixed {
            position fixed
            z-index 800
            bottom 16px
            padding 0 16px
            left 0
            right 0
            display flex
            justify-content space-around
            align-items center
          }
          
          &__file {
            height 31px
            width 31px
            cursor pointer
            //background gray
          }
        }
        
        #overlay {
          position absolute
          top 0
          left 0
        }
      `}</style>
    </div>
  )
}

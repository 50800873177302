import React, { useRef, useState } from "react"
import Rating from "react-rating"
import { Modal } from "./Modal"
import { CloseIcon } from "../assets"
import { Button } from "./Button"
import { useTranslation } from "react-i18next"
import starGrey from "assets/star-grey.png"
import starYellow from "assets/star-yellow.png"

export interface feedbackData {
    feedback: string,
    rating: number
}

export type RatingPopupProps = {
    visible: boolean
    onClose(): void
    onSubmit(data: feedbackData): void
}

export const RatingPopup: React.FC<RatingPopupProps> = ({
    visible,
    onClose,
    onSubmit
}) => {

    const textareaRef = useRef<HTMLTextAreaElement | null>(null)
    const { t } = useTranslation()
    const [message, setMessage] = useState("")
    const [ratingValue, setRatingValue] = useState(0)

    const handleSubmit = () => {
        onSubmit({ feedback: message, rating: ratingValue})
    }

    const handleChange = (value) => {
        console.log(value)
        setRatingValue(value)
    }


    return (
        <Modal visible={visible}>
            <div className="modal-container">
                <div className={"header"}>
                    <h3 className={"title"}>{t("text.rating")}</h3>
                    <div style={{ cursor: "pointer" }} onClick={onClose}>
                        <CloseIcon fill={"#7A8189"} />
                    </div>
                </div>
                <span className={"subtitle"}>
                {t("text.your_feedback_is_our_development")}
                </span>

                <div className="rating-wrapper">
                    <Rating
                        placeholderRating={ratingValue}
                        emptySymbol={<img src={starGrey} className="icon" alt="star_grey" />}
                        placeholderSymbol={<img src={starYellow} className="icon" alt="star_yellow" />}
                        fullSymbol={<img src={starYellow} className="icon" alt="star_yellow" />}
                        fractions={2}
                        onChange={handleChange}
                    />
                </div>


                <textarea
                    ref={textareaRef}
                    className={"textarea"}
                    rows={5}
                    placeholder={t("text.leave_a_reason_rating")}
                    onChange={e => setMessage(e.target.value)}
                    value={message}
                />

                <Button title={t("button.submit")} onClick={handleSubmit}/>

                {/*language=Stylus*/}
                <style jsx>{`
                    .modal-container {
                        background #FFF
                        padding 16px
                        border-radius 4px
                        width 520px
                        
                        @media only screen and (max-width 830px) {
                        width 100%
                        }
                    }

                    .header {
                        display flex
                        justify-content space-between
                        align-items center
                    }

                    .title {
                        font-size 16px
                        line-height 24px
                        font-weight 500
                    }

                    .subtitle {
                        display inline-block
                        font-size 14px
                        line-height 20px
                        color #7A8189
                        margin-bottom 16px
                    }

                    .textarea {
                        width 100%
                        font-family "Roboto", sans-serif
                        resize none
                        padding 8px
                        background #F2F2F2
                        border none
                        border-radius 4px
                        margin-top 8px
                        margin-bottom 16px
                        
                        &:focus {
                        outline 2px solid #FF8200
                        -moz-outline-radius 4px
                        -webkit-outline-radius 4px
                        }
                    }

                    .rating-wrapper {
                        margin-bottom: 16px
                        img {
                            width: 30px
                        }
                    }
                `}</style>
            </div>
        </Modal>
    )    
}
import React from "react"
import { LoadingAnim } from "../assets"
import { Modal } from "./Modal"

export type LoadingProps = {
  visible: boolean
}

export const Loading: React.FC<LoadingProps> = ({ visible, children }) => {
  return (
    <Modal visible={visible}>
      <div className={"container"}>
        <LoadingAnim />
        <p className="content">{children}</p>
      </div>

      {/*language=Stylus*/}
      <style jsx>{`
        .image__loading {
          width 100px
          height 100px
        }
        .content {
          color: #fff;
          text-align: center;
          font-size: 16px;
          padding: 24px
        }
      `}</style>
    </Modal>
  )
}

import React, { Suspense } from "react"
import { Router, Route, Switch } from "react-router-dom"
import { createBrowserHistory } from "history"
import { CameraPage } from "./pages/CameraPage"
// import { PreviewPage } from "./pages/PreviewPage"
import { PreviewPhotoLitePage } from "./pages/PreviewPhotoLitePage"
import { WelcomePage } from "./pages/WelcomePage"
import { SignUpPage } from "./pages/SignUpPage"
import { ReviewPage } from "./pages/ReviewPage"
import { Loading } from "./components/Loading"
import { InstructionPage } from "./pages/InstructionPage"
import { ConfirmPage } from "./pages/ConfirmPage"
import { StatusPage } from "./pages/StatusPage"
import { NotFoundPage } from "./pages/NotFoundPage"
import i18next from "i18next"
import { useAsync } from "react-use"
import { ProcessPhotosPage } from "./pages/ProcessPhotosPage"
import { PreviewPhotosPage } from "./pages/PreviewPhotosPage"
import { InstructionPhotoPage } from "./pages/InstructionPhotoPage"
import Checkout from "./pages/Checkout/Checkout"
import * as Sentry from "@sentry/react"
import { Integrations } from "@sentry/tracing"

const history = createBrowserHistory()

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://ab89489fc4e84be8b1ce705b89b5e19d@o426452.ingest.sentry.io/5623956",
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history)
      })
    ],
    environment: process.env.NODE_ENV,
    ignoreErrors: [/died in status NOT_MOUNTED/],
    beforeSend(event, hint) {
      // Check if it is an exception, and if so, show the report dialog
      if (event.exception) {
        Sentry.showReportDialog({
          eventId: event.event_id
        })
      }

      return event
    },

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0
  })
}

export const App = () => {
  useAsync(async () => {
    await i18next.init({
      debug: false
    })
  }, [])

  return (
    <Router history={history}>
      <Suspense fallback={<Loading visible={true} />}>
        <div className={"container"}>
          <Switch>
            <Route exact path="/">
              <WelcomePage />
            </Route>
            <Route exact path="/instruction">
              <InstructionPage />
            </Route>
            <Route exact path="/photo-instruction">
              <InstructionPhotoPage />
            </Route>
            <Route exact path="/process-photos">
              <ProcessPhotosPage />
            </Route>
            <Route exact path="/preview-photos">
              <PreviewPhotosPage />
            </Route>
            <Route exact path="/camera">
              <CameraPage />
            </Route>
            <Route path="/preview">
              {/* <PreviewPage /> */}
              <PreviewPhotoLitePage />
            </Route>
            <Route path="/sign-up">
              <SignUpPage />
            </Route>
            <Route path="/checkout">
              <Checkout />
            </Route>
            <Route path="/review">
              <ReviewPage />
            </Route>
            <Route path="/confirm">
              <ConfirmPage />
            </Route>
            <Route path="/status">
              <StatusPage />
            </Route>
            <Route>
              <NotFoundPage />
            </Route>
          </Switch>

          {/*language=Stylus*/}
          <style global jsx>{`
                .react-dropdown-select {
                  height 44px
                  
                  &-dropdown {
                    width: 100%;
                    top: 45px;
                    border: 1px solid #BCBCBC;
                  }
                  
                  &-content {
                    margin-left 10px
                    display flex
                    align-items center
                  }
                  
                  &-dropdown-handle {
                    margin-right 10px
                  }
                }
                
                .checkbox {
                  --background: #fff
                  --border: #d1d6ee
                  --border-hover: #bbc1e1
                  --border-active: #FF8200
                  --tick: #fff
                  position: relative
                  input,
                  svg {
                    width: 21px
                    height: 21px
                    display: block
                  }
    
                  input {
                    -webkit-appearance: none
                    -moz-appearance: none
                    position: relative
                    outline: none
                    background: var(--background)
                    border: none
                    margin: 0
                    padding: 0
                    cursor: pointer
                    border-radius: 4px
                    transition: box-shadow 0.3s
                    box-shadow: inset 0 0 0 var(--s, 1px) var(--b, var(--border))
                    &:hover {
                      --s: 2px
                      --b: var(--border-hover)
                    }
                    &:checked {
                      --b: var(--border-active)
                    }
                  }
    
                  svg {
                    pointer-events: none
                    fill: none
                    stroke-width: 2px
                    stroke-linecap: round
                    stroke-linejoin: round
                    stroke: var(--stroke, var(--border-active))
                    position: absolute
                    top: 0
                    left: 0
                    width: 21px
                    height: 21px
                    transform: scale(var(--scale, 1)) translateZ(0)
                  }
    
                  &.path {
                    input {
                      &:checked {
                        --s: 2px
                        transition-delay: 0.4s
                        & + svg {
                          --a: 16.1 86.12
                          --o: 102.22
                        }
                      }
                    }
                    svg {
                      stroke-dasharray: var(--a, 86.12)
                      stroke-dashoffset: var(--o, 86.12)
                      transition: stroke-dasharray 0.6s, stroke-dashoffset 0.6s
                    }
                  }
    
                  &.bounce {
                    --stroke: var(--tick)
                    input {
                      &:checked {
                        --s: 11px
                        & + svg {
                          animation: bounce 0.4s linear forwards 0.2s
                        }
                      }
                    }
                    svg {
                      --scale: 0
                    }
                  }
                }
    
                @keyframes bounce {
                  50% {
                    transform: scale(1.2)
                  }
                  75% {
                    transform: scale(0.9)
                  }
                  100% {
                    transform: scale(1)
                  }
                }
              `}</style>
        </div>
      </Suspense>
    </Router>
  )
}

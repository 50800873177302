import React from "react"
import ReactDOM from "react-dom"
import * as serviceWorker from "./serviceWorker"
import "./index.css"
import "./animation.css"
import { App } from "./App"
import './i18n'
import GA4React from "ga-4-react";
const ga4react = new GA4React("G-4D9FN8FVV0");

(async _ => {  
    await ga4react.initialize()
    .then(res => console.log("Analytics Success."))
    .catch(err => console.log("Analytics Failure."))
    .finally(() => {
        ReactDOM.render(
            <App />,
            document.getElementById("root")
        );
    });
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
